import './story-panel.scss';
import {useState} from 'react';
import {Story, setStateFn} from '../../../../common/types';
import StoryPlayer from "../StoryPlayer/StoryPlayer.tsx";

const CHARACTER_CYCLE_INTERVAL = 3;

interface StoryPanelProps {
  isOpen: boolean;
  onClose: () => void;
  onStoryComplete?: (index: number) => void;
  storyIndex: number;
  stories: Story[];
  isAudioPlaying: boolean;
  setIsAudioPlaying: setStateFn<boolean>;
}

function StoryPanel(props: StoryPanelProps) {
  const {
    isOpen, onClose, onStoryComplete, storyIndex, stories,
    isAudioPlaying, setIsAudioPlaying,
 } = props;

  const [audioUrl, setAudioUrl] = useState<string|null>(
    stories[storyIndex] ? stories[storyIndex].audio_file : null
  );
  const [isIndexMenuOpen, setIsIndexMenuOpen] = useState(false);

  const closeIndexMenu = () => {
    isIndexMenuOpen && setIsIndexMenuOpen(false);
  }

  if (isOpen && audioUrl != stories[storyIndex].audio_file) {
    closeIndexMenu();
    setAudioUrl(stories[storyIndex].audio_file);
  } else if (!isOpen && audioUrl != null) {
    setAudioUrl(null);
  }

  if (isOpen) {
    return <StoryPlayer
      audioUrl={audioUrl!} onClose={onClose} onStoryComplete={() => onStoryComplete?.(storyIndex)}
      characterPortraitUrl={stories[storyIndex].character_portrait} characterTitle={stories[storyIndex].title}
      isAudioPlaying={isAudioPlaying} setIsAudioPlaying={setIsAudioPlaying}
    />
  }

  return null;
}

export default StoryPanel;

import './App.scss';
import { useEffect, useState } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import StoryPlayerViewer from './components/StoryPlayerViewer/StoryPlayerViewer.tsx';
import { fetchStories } from '../../common/wp_api.ts';
import { StoryPlayerData, PhpData } from '../../common/types';

declare const php_data: PhpData;

gsap.registerPlugin(useGSAP);

function App() {
  const [storyData, setStoryData] = useState<StoryPlayerData>();

  useEffect(() => {
    const fetchData = async () => {
      const storyData = await fetchStories(php_data.post_id);
      setStoryData(storyData);
    };

    fetchData().catch(err => {
      const msg = `error fetching stories data ('${err}')`;
      console.error(msg);
    });
  }, [php_data.post_id]); //eslint-disable-line react-hooks/exhaustive-deps

  if (!storyData) {
    return <div className="storyPlayerViewer__loadingPlaceholder">Loading...</div>;
  }

  if ('acf' in storyData) {
    return <StoryPlayerViewer storyData={storyData} postTitle={php_data.post_title} />;
  } else {
    return (
      <div className="storyPlayerViewer__appError">Error: could not load Circuville data.</div>
    );
  }
}

export default App;

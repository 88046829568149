import React from 'react';
import ReactDOM from 'react-dom/client';
import {register} from 'swiper/element/bundle';
import App from './App.tsx';

register();

ReactDOM.createRoot(document.getElementById('dzi-story-player-frontend')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

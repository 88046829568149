import './intro-panel.scss';
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay, faPlay } from '@fortawesome/free-solid-svg-icons';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import '../AudioPlayer/AudioPlayer';
import { setStateFn, Story } from '../../../../common/types';
import StoryPlayer from '../StoryPlayer/StoryPlayer.tsx';

interface IntroPanelProps {
  introTitle: string;
  introText: string;
  introAudio: string;
  stories: Story[];
  isOpen: boolean;
  setIsVisible: setStateFn<boolean>;
  onStartStory: (index: number) => void;
}

function IntroPanel(props: IntroPanelProps) {
  const { introAudio, setIsVisible, isOpen, onStartStory } = props;

  const swiperRef = useRef<any>();
  const gsapTl = useRef<GSAPTimeline>();
  const [isAudioPlayerActive, setIsAudioPlayerActive] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  useGSAP(
    () => {
      if (isOpen && !isAudioPlayerActive) {
        gsapTl.current = gsap.timeline({ paused: true });
        gsapTl.current.set('.fadein', { autoAlpha: 0 });
        gsapTl.current.to('.fadein', {
          autoAlpha: 1,
          duration: 0.75,
          stagger: 0.03,
        });
      }
    },
    { dependencies: [isOpen, isAudioPlayerActive] },
  );

  useEffect(() => {
    if (isOpen && !isAudioPlayerActive) gsapTl.current!.restart();
  }, [isOpen, isAudioPlayerActive]);

  useEffect(() => {
    setIsVisible(isOpen && !isAudioPlayerActive);
  }, [isOpen, isAudioPlayerActive]);

  // Note: as per https://swiperjs.com/blog/using-swiper-element-in-react
  useEffect(() => {
    if (swiperRef.current) {
      const swiperParams = {
        slidesPerView: 'auto',
        initialSlide: 2,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 0,
        navigation: true,
        injectStylesUrls: [
          '/wp-content/plugins/dzi-story-player/frontend/src/components/IntroPanel/swiper.css',
        ],
      };

      Object.assign(swiperRef.current, swiperParams);

      swiperRef.current.initialize();

      const observer = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (swiperRef.current) {
            const swiper = swiperRef.current.swiper;

            if (entry.contentRect.width < 1000) {
              swiper.enable();
            } else {
              swiper.slideTo(2, 0);
              swiper.disable();
            }
          }
        }
      });

      observer.observe(swiperRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isOpen, isAudioPlayerActive]);

  const startStory = (idx: number) => {
    setIsAudioPlaying(false);
    onStartStory(idx);
  };

  const onOpenAudioPlayer = () => {
    setIsAudioPlayerActive(true);
    setIsAudioPlaying(true);
  };

  const onIntroAudioComplete = () => {
    setIsAudioPlayerActive(false);
  };

  const onAudioPlayerClose = () => {
    setIsAudioPlayerActive(false);
  };

  if (isOpen) {
    if (!isAudioPlayerActive) {
      return (
        <div className="introPanel">
          <div className="introPanel__inner">
            <div className="introPanel__wrap">
              <div
                className="fadein introPanel__text"
                dangerouslySetInnerHTML={{ __html: props.introText }}
              />
              <div className="fadein introPanel__play">
                <button onClick={onOpenAudioPlayer}>
                  intro
                  <span>
                    <FontAwesomeIcon icon={faPlay} />
                  </span>
                </button>
              </div>
            </div>

            <div className="introPanel__links">
              <swiper-container init={false} ref={swiperRef}>
                {props.stories &&
                  props.stories.map((story, i) => (
                    <swiper-slide key={`storyLink-${i}`}>
                      <div className="fadein story-link">
                        <a className="introPanel__startButton" onClick={() => startStory(i)}>
                          <img src={story.character_portrait} alt={story.title} />
                          <span>
                            <strong>{story.title}</strong> {story.description}{' '}
                            <FontAwesomeIcon icon={faCirclePlay} />
                          </span>
                        </a>
                      </div>
                    </swiper-slide>
                  ))}
              </swiper-container>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <StoryPlayer
          audioUrl={introAudio}
          characterTitle={'Welcome to Circuville'}
          onClose={onAudioPlayerClose}
          onStoryComplete={onIntroAudioComplete}
          isAudioPlaying={isAudioPlaying}
          setIsAudioPlaying={setIsAudioPlaying}
        />
      );
    }
  }

  return null;
}

export default IntroPanel;

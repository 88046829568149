import {useEffect, useRef} from 'react';

/** Returns the given time (in seconds) pretty-printed as m:ss. */
function formatPlayerTime(seconds: number): string {
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);

  return `${mins}:${secs.toString().padStart(2, '0')}`;
}

/**
 * Returns the descriptive text for err if it is a [MediaError](https://developer.mozilla.org/en-US/docs/Web/API/MediaError/code)
 * code, or returns it unmodified if it is a string.
 */
function howlerMediaErrorToText(err: unknown): string {
  if (typeof err === 'string') return err;

  switch (err) {
    case 1: return 'MEDIA_ERR_ABORTED';
    case 2: return 'MEDIA_ERR_NETWORK';
    case 3: return 'MEDIA_ERR_DECODE';
    case 4: return 'MEDIA_ERR_SRC_NOT_SUPPORTED';
    default: throw new Error('Unknown MediaError code');
  }
}

/**
 * Returns incremented index, looping to 0 at total - 1, or -1 if it should not
 * loop and would go out of range.
 */
function nextIndex(index: number, total: number, loop: boolean): number {
  return index < total - 1 || loop ? (index + 1) % total : -1;
}

/**
 * Returns deccremented index, looping around to total at 0; or returns -1 if it
 * should not loop and would go out of range.
 */
function prevIndex(index: number, total: number, loop: boolean): number {
  return index > 0 || loop ? (index - 1) % total : -1;
}

/**
 * Returns the current URL hash, without the '#'.
 */
function readURLHash(): string {
  const hash = window.location.hash;

  return hash.length > 0 ? hash.substring(1) : '';
}

/**
 * Sets the given `value` as the URL hash (by prefixing it with '#').
 *
 * @param value The hash to set, pass an empty string to clear the hash.
 */
function setURLHash(value: string): void {
  window.history.pushState(null, '', '#' + value);
}

/**
 * A more react-intuitive variant of setInterval: both parameters are reactive.
 * See: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @param callback Function to invoke each time the interval has passed.
 * @param delay Interval in milliseconds, null to disable.
 */
function useInterval(callback: () => void, delay: number|null): void {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current!();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}


export {
  formatPlayerTime, howlerMediaErrorToText, nextIndex, prevIndex,
  readURLHash, setURLHash, useInterval,
};

import { StoryPlayerData } from './types';

const API_ENDPOINT_BASE = `/wp-json/wp/v2`;

/** Returns a promise that resolves to the image map data for the requested post ID. */
async function fetchStories(postId: number): Promise<StoryPlayerData> {
  const param = '?acf_format=standard';

  return fetch(`${API_ENDPOINT_BASE}/story-player/${postId}${param}`).then(res => res.json());
}

export { fetchStories };

import './story-player.scss';
import gsap from 'gsap';
import {useGSAP} from '@gsap/react';
import {setStateFn} from "../../../../common/types";
import AudioPlayer from "../AudioPlayer/AudioPlayer.tsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

interface StoryPlayerProps {
  characterPortraitUrl?: string;
  characterTitle?: string;
  audioUrl: string;
  onClose: () => void;
  onStoryComplete?: () => void;
  isAudioPlaying: boolean;
  setIsAudioPlaying: setStateFn<boolean>;
}

function StoryPlayer(props: StoryPlayerProps) {
  const {
    characterPortraitUrl, characterTitle, audioUrl,
    onClose, onStoryComplete,
    isAudioPlaying, setIsAudioPlaying,
  } = props;

  useGSAP(() => {
    gsap.set('.bounce', { autoAlpha: 0, y: '+150px' });
    gsap.to('.bounce', { autoAlpha: 1, duration: 0.25 });
    gsap.to('.bounce', {
      y: 0,
      ease: 'power2.in',
      duration: 0.25,
      // ease: 'bounce',
      // duration: 0.9,
      stagger: 0.05,
    });
  });


  return <div className='storyPanel'>
    <div className='storyPanel__inner'>
      { (characterPortraitUrl || characterTitle) && <div className='storyPanel__character'>
        <div className='bounce storyPanel__characterInner'>
          {characterPortraitUrl && <img src={characterPortraitUrl} alt={characterTitle} /> }
          {characterTitle && <div className='storyPanel__title'>{characterTitle}</div>}
        </div>
      </div>}

      <div className="bounce storyPanel__player">
        <AudioPlayer audioUrl={audioUrl} onComplete={() => onStoryComplete?.()} isPlaying={isAudioPlaying}
                     setIsPlaying={setIsAudioPlaying}/>
        <button className='storyPanel__closeButton' onClick={onClose}><FontAwesomeIcon icon={faXmark}/></button>
      </div>
    </div>
  </div>;
}

export default StoryPlayer;

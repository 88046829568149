import {ChangeEvent, useEffect, useRef, useState} from 'react';

interface SeekBarProps {
  className?: string;
  value: number;
  onChange: (value: number) => void;  // Fires continuously while dragging
  onChanged: (value: number) => void;  // Fires when dragging has ended
}

function SeekBar({className, value, onChange, onChanged}: SeekBarProps) {
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState(0);
  const seekBar = useRef<HTMLInputElement|null>(null);

  useEffect(() => {
    if (!isDragging) setPosition(value);
  }, [value]);

  const mouseDown = () => {
    setIsDragging(true);
  };

  const mouseUp = () => {
    onChanged(position);
    setIsDragging(false);
  };

  const changePosition = (ev: ChangeEvent<HTMLInputElement>) => {
    setPosition(parseFloat(ev.target.value));
    onChange(position);
  };

  return <>
    <div className="progressbar" style={ {width: position * 100 + '%'} }/>
    <input
      ref={seekBar} className={className} type='range' min='0' max='1' step='any' value={position}
      onChange={changePosition} onMouseDown={mouseDown} onTouchStart={mouseDown}
      onMouseUp={mouseUp} onTouchEnd={mouseUp}
    /></>;
}

export default SeekBar;

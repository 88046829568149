import './map-controls.scss';
import {faPlus, faMinus,faHouse, faExpand} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface MapControlsProps {
  isOpen: boolean;
}

function MapControls( {isOpen}: MapControlsProps ) {
  let clsOverlay = isOpen ? "overlay-active" : "overlay-inactive";
    return <div className={`mapControls ${clsOverlay}`}>
      <button className='mapControls_zoomIn' id='mapControls_zoomIn'><FontAwesomeIcon icon={faPlus}/></button>
      <button className='mapControls_zoomOut' id='mapControls_zoomOut'><FontAwesomeIcon icon={faMinus}/></button>
      <button className='mapControls_home' id='mapControls_home'><FontAwesomeIcon icon={faHouse}/></button>
    </div>;

}

export default MapControls;

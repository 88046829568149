/**
 * Openseadragon base from https://github.com/openseadragon/openseadragon/issues/1941#issuecomment-789180368
 */
import './story-player-viewer.scss';
import {useEffect, useRef, useState} from 'react';
import OpenSeadragon, {GestureSettings, Point} from 'openseadragon';
import StoryPanel from '../StoryPanel/StoryPanel.tsx';
import {readURLHash, setURLHash} from '../../utils.ts';
import {StoryPlayerData} from '../../../../common/types';
import IntroPanel from '../IntroPanel/IntroPanel.tsx';
import MapControls from "../MapControls/MapControls.tsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowLeft, faArrowRight, faArrowUp} from "@fortawesome/free-solid-svg-icons";

const AUTOPLAY = true;
const OSD_VIEWER_ID = 'osdViewer';
const STORY_HASH_PREFIX = 's';
const PAN_BUTTON_DIST = 0.01;

enum Direction { Up , Right, Down, Left }

function StoryPlayerViewer({storyData, postTitle}: { storyData: StoryPlayerData, postTitle: string}) {
  const urlHash = readURLHash();
  const immediateOpen = urlHash.startsWith(STORY_HASH_PREFIX) ? parseInt(urlHash.substring(STORY_HASH_PREFIX.length)) : -1;

  const viewer = useRef<OpenSeadragon.Viewer|null>(null);
  const [isPanelOpen, setIsPanelOpen] = useState(immediateOpen >= 0);
  const [isIntroOpen, setIsIntroOpen] = useState(immediateOpen < 0);
  const [isIntroVisible, setIsIntroVisible] = useState(immediateOpen < 0);
  const [storyIndex, setStoryIndex] = useState(immediateOpen ?? 0);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  let clsOverlay = isIntroVisible ? "overlay-active" : "overlay-inactive";

  const stories = storyData.acf.stories;

  function numStories(): number {
    return Array.isArray(stories) ? stories.length : 0;
  }

  useEffect(() => {
    const gestureSettings: GestureSettings = { clickToZoom: false };

    if (!storyData.acf.dzi_url) {
      console.warn('DZI URL empty, skipping openseadragon initialisation');
      return;
    }

    // About keeping the viewport covered:
    // - https://github.com/openseadragon/openseadragon/issues/2344
    // - https://github.com/openseadragon/openseadragon/issues/1284
    viewer.current = OpenSeadragon({
      id: OSD_VIEWER_ID,
      prefixUrl: '//openseadragon.github.io/openseadragon/images/',
      // debugMode: true,
      tileSources: [storyData.acf.dzi_url],
      zoomInButton: 'mapControls_zoomIn',
      zoomOutButton: 'mapControls_zoomOut',
      homeButton: 'mapControls_home',

      gestureSettingsMouse: gestureSettings,
      gestureSettingsTouch: gestureSettings,
      gestureSettingsPen: gestureSettings,
      gestureSettingsUnknown: gestureSettings,

      constrainDuringPan: true,
      homeFillsViewer: true,
      // minZoomLevel: 1,  // Controlled via 'homeFillsViewer'
      minZoomImageRatio: 1,
      maxZoomPixelRatio: 1,  // Should not be too small as text must be readable on mobile devices
      visibilityRatio: 1,
    });

    // FIXME: `applyConstraints()` applies the new min zoom (updated by osd)
    //  correctly, however it gets overwritten by 'old' zoom levels somehow
    //  (zoom events with old values are logged). Possible starting point:
    //  https://github.com/openseadragon/openseadragon/issues/2526
    //
    // The (0!) timeout sort of fixes this, but causes slight visual jitter.
    viewer.current.addHandler('resize', () => {
      viewer.current!.viewport.applyConstraints(true);

      setTimeout(() => {
        viewer.current!.viewport.applyConstraints(true);
      }, 0);
    });

    // Cleanup (equal to componentWillUnmount)
    return () => {
      viewer.current!.destroy();
      viewer.current = null;
    };
  }, [storyData]);

  useEffect(() => {
    if (isPanelOpen) {
      document.body.classList.add('map-modal-open');
    } else {
      document.body.classList.remove('map-modal-open');
    }
  }, [isPanelOpen]);

  useEffect(() => {
    if (isIntroOpen) setURLHash('');
  }, [isIntroOpen]);

  const canPan = (dir: Direction): boolean => {
    return true;
  };

  const pan = (dir: Direction) => {
    if (!viewer.current) return;

    const vp = viewer.current.viewport;
    const hor = dir == Direction.Left || dir == Direction.Right;
    const neg = dir == Direction.Left || dir == Direction.Up ? -1 : 1;
    const delta = hor ? new Point(PAN_BUTTON_DIST * neg, 0) : new Point(0, PAN_BUTTON_DIST * neg);

    vp.panBy(delta);
    vp.applyConstraints();
  };

  const jumpToStory = (index: number) => {
    setURLHash(`${STORY_HASH_PREFIX}${index}`);

    setStoryIndex(index);
    setIsAudioPlaying(AUTOPLAY);
    setIsPanelOpen(true);
  };

  const onStartStory = (index: number) => {
    setIsIntroOpen(false);

    if (numStories() > index) {
      jumpToStory(index);
      setIsPanelOpen(true);
    }
  };

  const onCloseStoryPanel = () => {
    setIsIntroOpen(true);
    setIsPanelOpen(false);
  };

  return <div id="storyPlayerViewer">
    <div id={OSD_VIEWER_ID} />
    <div className={`storyPlayerViewer__arrows ${clsOverlay}`}>
      <button className='scrollArrowUp' disabled={!canPan(Direction.Up)} onClick={() => pan(Direction.Up)}>
        <FontAwesomeIcon icon={faArrowUp}/>
      </button>
      <button className='scrollArrowRight' disabled={!canPan(Direction.Right)} onClick={() => pan(Direction.Right)}>
        <FontAwesomeIcon icon={faArrowRight}/>
      </button>
      <button className='scrollArrowDown' disabled={!canPan(Direction.Down)} onClick={() => pan(Direction.Down)}>
        <FontAwesomeIcon icon={faArrowDown}/>
      </button>
      <button className='scrollArrowLeft' disabled={!canPan(Direction.Left)} onClick={() => pan(Direction.Left)}>
        <FontAwesomeIcon icon={faArrowLeft}/>
      </button>
    </div>
    <MapControls isOpen={isIntroVisible}  />
    <IntroPanel
      introTitle={storyData.title.rendered} introText={storyData.acf.intro_text}
      introAudio={storyData.acf.intro_audio_file} stories={stories}
      setIsVisible={setIsIntroVisible} isOpen={isIntroOpen} onStartStory={onStartStory}
    />
    <StoryPanel
      isOpen={isPanelOpen}
      onClose={onCloseStoryPanel}
      // onStoryComplete={onStoryComplete}
      storyIndex={storyIndex}
      stories={storyData.acf.stories}
      isAudioPlaying={isAudioPlaying}
      setIsAudioPlaying={setIsAudioPlaying}
    />
  </div>
}

export default StoryPlayerViewer;

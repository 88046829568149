/**
 * Example for UI: https://medium.com/@mohammadreza.tatlari8/a-simple-music-player-with-howler-and-react-74b47e892be1
 *
 * Should any double audio playing occur, perhaps storing the fragment ID and passing it to calls might help.
 */
import './audio-player.scss';
import {Howl} from 'howler';
import {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPause, faPlay} from '@fortawesome/free-solid-svg-icons';
import {formatPlayerTime, howlerMediaErrorToText} from '../../utils.ts';
import {setStateFn} from '../../../../common/types';
import SeekBar from '../SeekBar/SeekBar.tsx';

interface AudioPlayerProps {
  audioUrl: string|null;
  onComplete?: () => void;
  isPlaying: boolean;
  setIsPlaying?: setStateFn<boolean>;
}

function AudioPlayer({ audioUrl, onComplete, isPlaying, setIsPlaying }: AudioPlayerProps) {
  const player = useRef<Howl|null>(null);
  const [duration, setDuration] = useState(0);
  const [position, setPosition] = useState(0);
  const posChangedFromBar = useRef(false);

  useEffect(() => {
    // Set at least position to 0 when (re)initializing to prevent the seek bar
    // from acting jumpy and to show cleaner values in the panel.
    setPosition(0);
    // setDuration(0);

    player.current = audioUrl ? new Howl({
      src: [audioUrl],
      html5: true,
      preload: true,
      autoplay: isPlaying,
      onend: onComplete,
      onloaderror: (_, err) => {
        console.error(`error loading audio URL '${audioUrl}' (${howlerMediaErrorToText(err)})`);
      }
    }) : null;

    let posIntervalId = setInterval(() => {
      if (!posChangedFromBar.current) {
        setPosition(player.current ? player.current.seek() : 0);
      }
    }, 1000);

    player.current!.on('load', () => {
      setDuration(player.current!.duration());
    });

    player.current!.on('playerror', (_, err) => {
      console.error(`error playing audio URL '${audioUrl}'`, err);
    });

    return () => {
      if (player.current) {
        player.current.unload();
        player.current = null;
      }

      if (posIntervalId >= 0) {
        clearInterval(posIntervalId);
        posIntervalId = -1;
      }
    };
  }, [audioUrl]);  // No dep on `onComplete`, as this triggers the effect on opening/closing the index menu

  useEffect(() => {
    setPlayState(isPlaying);
  }, [isPlaying]);

  const togglePlay = () => {
    if (!player.current) return;

    setPlayState(!isPlaying);
  };

  const setPlayState = (playing: boolean) => {
    if (!player.current) return;

    if (setIsPlaying) setIsPlaying(playing);

    if (playing != player.current?.playing()) {
      playing ? player.current.play() : player.current?.pause();
    }
  };

  const changePosition = (normPos: number) => {
    posChangedFromBar.current = true;
    setPosition(normPos * duration);
  };

  const changePositionFinished = (normPos: number) => {
    posChangedFromBar.current = false;
    setPosition(normPos * duration);
    player.current!.seek(normPos * duration);
  };

  return <div className='audioplayer'>
    <button className='audioplayer__play_pause' onClick={togglePlay}>
      {isPlaying ? <FontAwesomeIcon icon={faPause}/> : <FontAwesomeIcon icon={faPlay}/>}
    </button>

    <div className='progress__number'>
    {player.current && player.current.state() == 'loaded'
      ? <>
        <span className='audioplayer__position'>{formatPlayerTime(position)}</span>
        &nbsp;/&nbsp;
        <span className='audioplayer__duration'>{formatPlayerTime(duration)}</span>
        </>
      : <span className='audioplayer__loading'>...</span>
    }
    </div>

    <div className='audioplayer__seekbar-wrapper'>
      <SeekBar className='audioplayer__seek' value={duration > 0 ? position / duration : 0}
               onChange={changePosition} onChanged={changePositionFinished}/>
    </div>
  </div>
}

export default AudioPlayer;
